import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	Grid,
	ListItem,
	ListItemText,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import CenteredLoadingSpinner from "../../components/CenteredLoadingSpinner";
import { ClientLink } from "../../components/ClientLink";
import { GridGrow } from "../../components/GridGrow";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { PageTitle } from "../../components/PageTitle";
import { Price } from "../../components/Price";
import { ServerErrorView } from "../../components/ServerErrorView";
import { GeneralLedgerAccount } from "../../entities/accounting/GeneralLedgerAccount";
import { PaymentReversalTransaction } from "../../entities/accounting/PaymentReversalTransaction";
import { PaymentTransaction } from "../../entities/accounting/PaymentTransaction";
import { Customer } from "../../entities/customer/Customer";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import { LedgerAccountService } from "../../services/accounting/LedgerAccountsService";
import { TransactionService } from "../../services/accounting/TransactionService";
import { InvoiceService } from "../../services/billing/InvoiceService";
import { CustomerService } from "../../services/customer/CustomerService";
import { NotFoundResult, ServerError } from "../../services/server/WebClient";
import { printDate } from "../../utility/PrintDate";
import { AllocatePaymentTransactionDialog } from "./dialog/AllocatePaymentTransactionDialog";
import EmailIcon from "@material-ui/icons/Email";
import DownloadIcon from "@material-ui/icons/GetApp";
import { EmailReceiptDialog } from "./dialog/EmailReceiptDialog";
import { RedText } from "../../components/RedText";

export function PaymentDetailPage() {
	const alert = useAlert();

	const params = useParams<{ paymentTransactionId: string }>();
	const paymentTransactionId = params.paymentTransactionId;

	const [serverError, setServerError] = useState<ServerError | NotFoundResult>();
	const [paymentTransaction, setPaymentTransaction] = useState<PaymentTransaction>();
	const [paymentReversal, setPaymentReversal] = useState<PaymentReversalTransaction>();
	const [customer, setCustomer] = useState<Customer>();
	const [accounts, setAccounts] = useState<GeneralLedgerAccount[]>();
	const [disabled, setDisabled] = useState(false);
	const [showPaymentReversalDialog, setShowPaymentReversalDialog] = useState(false);
	const [showAllocateDialog, setAllocateDialog] = useState(false);
	const [showEmailReceiptDialog, setShowEmailReceiptDialog] = useState(false);

	const accountsReceivable = accounts?.find((a) => a.id === paymentTransaction?.accountsReceivableAccountId);
	const assetAccount = accounts?.find((a) => a.id === paymentTransaction?.assetAccountId);

	const paymentTransactionCustomerId = paymentTransaction?.customerId;
	const reversalTransactionId = paymentTransaction?.reversalTransactionId ?? null;
	const isReversed = reversalTransactionId !== null;

	useEffect(() => {
		async function loadPaymentTransaction(paymentTransactionId: string) {
			const result = await TransactionService.getPaymentTransaction(paymentTransactionId);
			if (result.success) {
				setPaymentTransaction(result.data);
			} else {
				setServerError(result);
			}
		}

		async function loadAccounts() {
			const result = await LedgerAccountService.getAll();
			if (result.success) {
				setAccounts(result.data);
			} else {
				setServerError(result);
			}
		}

		loadPaymentTransaction(paymentTransactionId);
		loadAccounts();
	}, [alert, paymentTransactionId]);

	useEffect(() => {
		async function loadCustomer(customerId: string) {
			const result = await CustomerService.get(customerId);
			if (result.success) {
				setCustomer(result.data);
			} else {
				setServerError(result);
			}
		}
		if (paymentTransactionCustomerId) {
			loadCustomer(paymentTransactionCustomerId);
		}
	}, [alert, paymentTransactionCustomerId]);

	useEffect(() => {
		async function loadReversalTransaction(paymentReversalId: string) {
			const result = await TransactionService.getPaymentReversalTransaction(paymentReversalId);
			if (result.success) {
				setPaymentReversal(result.data);
			} else {
				alert.serverError(result);
			}
		}
		if (reversalTransactionId) {
			loadReversalTransaction(reversalTransactionId);
		}
	}, [alert, reversalTransactionId]);

	const removeFromInvoice = async (request: { invoiceId: string; invoicePaymentId: string }) => {
		setDisabled(true);
		const result = await InvoiceService.removePayment({ invoiceId: request.invoiceId, invoicePaymentId: request.invoicePaymentId });
		setDisabled(false);
		if (result.success) {
			setPaymentTransaction(result.data.paymentTransaction);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const reversePayment = async (reason: string | null, isChargeback: boolean) => {
		setShowPaymentReversalDialog(false);
		setDisabled(true);
		const result = await TransactionService.reversePaymentTransaction({ paymentTransactionId, reason, isChargeback });
		setDisabled(false);
		if (result.success) {
			setPaymentTransaction(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onPaymentAllocation = (paymentTransaction: PaymentTransaction) => {
		setPaymentTransaction(paymentTransaction);
		setAllocateDialog(false);
	};

	const downloadReceipt = async () => {
		setDisabled(true);
		const result = await TransactionService.downloadReceipt(
			paymentTransactionId,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			`Receipt_${printDate.fileFriendlyDate(paymentTransaction!.created)}_${customer!.customerCode}.pdf`
		);
		setDisabled(false);
		if (result.error) {
			console.error(result);
			alert.serverError(result);
		}
	};

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!paymentTransaction || !customer) {
		return <OverlayLoadingScreen />;
	}

	return (
		<Grid container justify="center">
			<PageTitle title="Payment Details" />
			<ReversePaymentDialog
				open={showPaymentReversalDialog}
				paymentTransaction={paymentTransaction}
				customer={customer}
				onClose={() => setShowPaymentReversalDialog(false)}
				onConfirm={reversePayment}
			/>
			<AllocatePaymentTransactionDialog
				open={showAllocateDialog}
				onClose={() => setAllocateDialog(false)}
				paymentTransaction={paymentTransaction}
				onPaymentApplied={onPaymentAllocation}
			/>
			<EmailReceiptDialog
				open={showEmailReceiptDialog}
				onClose={() => setShowEmailReceiptDialog(false)}
				customer={customer}
				paymentTransactionId={paymentTransactionId}
			/>
			<Grid item md={8} sm={10} xs={12}>
				<Card>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title={`Payment Details${isReversed ? " (Reversed)" : ""}`} />
						</Grid>
						<GridGrow />
						{!isReversed && (
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									onClick={() => setShowPaymentReversalDialog(true)}
									disabled={disabled}
									style={{ marginRight: 5 }}
								>
									Reverse Payment
								</Button>
							</Grid>
						)}
						<Grid item>
							<Button variant="contained" color="primary" onClick={() => setShowEmailReceiptDialog(true)} disabled={disabled} style={{ marginRight: 5 }} startIcon={<EmailIcon />}>
								Email Receipt
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" color="primary" onClick={downloadReceipt} disabled={disabled} style={{ marginRight: 5 }} startIcon={<DownloadIcon />}>
								Pdf Receipt
							</Button>
						</Grid>
					</Grid>
					<CardContent>
						<Grid container spacing={2} justify="space-between">
							<Grid item>
								<ListItemText
									primary="Customer"
									secondary={
										<ClientLink color="textPrimary" to={routes.app.resolve.customerDetailPage(customer.id)}>
											{customer.name}
										</ClientLink>
									}
								/>
							</Grid>
							<Grid item>
								<ListItemText primary="Amount" secondary={<Price value={paymentTransaction.amount} doNotRound />} />
							</Grid>
							{paymentTransaction.type === "Card" && paymentTransaction.surchargeAmount != null && (
								<Grid item>
									<ListItemText primary={"Surcharge"} secondary={<Price value={paymentTransaction.surchargeAmount} />} />
								</Grid>
							)}
							<Grid item>
								<ListItemText
									primary="Payment Method"
									secondary={paymentTransaction.type === "Card" ? paymentTransaction.cardDescription : paymentTransaction.type}
								/>
							</Grid>
							{paymentTransaction.type === "Check" && paymentTransaction.checkNumber && (
								<Grid item>
									<ListItemText primary="Check Number" secondary={paymentTransaction.checkNumber} />
								</Grid>
							)}
							{paymentTransaction.type === "Card" && (
								<Grid item>
									<ListItemText primary={paymentTransaction.paymentProcessor + " Transaction Id"} secondary={paymentTransaction.chargeTransactionId} />
								</Grid>
							)}
							<Grid item>
								<ListItemText primary="Payment Date" secondary={paymentTransaction.created.toLocaleDateString()} />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h6">Applied to Accounts</Typography>
								<Grid container spacing={4}>
									{assetAccount && (
										<Grid item>
											<ListItemText primary={`${paymentTransaction.type} Account`} secondary={assetAccount.name} />
										</Grid>
									)}
									{accountsReceivable && (
										<Grid item>
											<ListItemText primary="Accounts Receivable" secondary={accountsReceivable.name} />
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography variant="h6">Applied to Invoices</Typography>
									</Grid>
									<GridGrow />
									{!isReversed && (
										<Grid item>
											<Button color="primary" variant="outlined" disabled={disabled} onClick={() => setAllocateDialog(true)}>
												Change Payment Allocation
											</Button>
										</Grid>
									)}
								</Grid>

								{paymentTransaction.allocations.map((allocation) => (
									<Grid container spacing={4} alignItems="center" wrap="nowrap" key={allocation.invoicePaymentId}>
										<Grid item>
											<Box minWidth={80}>
												<ListItemText
													primary={
														<ClientLink color="textPrimary" to={routes.app.resolve.invoiceDetailPage(allocation.invoiceId)}>
															{allocation.invoiceShortId}
														</ClientLink>
													}
													secondary={<Price value={allocation.amountApplied} doNotRound />}
												/>
											</Box>
										</Grid>
										{!isReversed && (
											<Grid item>
												<Button color="secondary" variant="outlined" disabled={disabled} onClick={() => removeFromInvoice(allocation)}>
													Remove
												</Button>
											</Grid>
										)}
									</Grid>
								))}
								{paymentTransaction.unallocatedAmount > 0 && (
									<ListItem style={{ paddingLeft: 0 }}>
										<ListItemText primary="Unallocated" secondary={<Price value={paymentTransaction.unallocatedAmount} doNotRound />} />
									</ListItem>
								)}
							</Grid>
							{isReversed && !paymentReversal && (
								<Grid item xs={12}>
									<CenteredLoadingSpinner />
								</Grid>
							)}
							{paymentReversal && (
								<Grid item xs={12}>
									<Typography variant="h6">Payment Reversal Details</Typography>
									<Grid container spacing={6}>
										<Grid item>
											<ListItemText primary="Reversal Date" secondary={paymentTransaction.created.toLocaleDateString()} />
										</Grid>
										<Grid item>
											<ListItemText primary="Reversed Amount" secondary={<Price value={paymentReversal.balanceChange} />} />
										</Grid>
										{paymentReversal.refundTransactionId && (
											<Grid item>
												<ListItemText
													primary={paymentReversal.paymentProcessor + " Refund Transaction Id"}
													secondary={paymentReversal.refundTransactionId}
												/>
											</Grid>
										)}
										{paymentTransaction.type === "Card" && !paymentReversal.refundTransactionId && (
											<Grid item>
												<ListItemText primary={<RedText>Card refund not issued</RedText>} />
											</Grid>
										)}
									</Grid>
									<Typography style={{ marginTop: 15 }}>Description</Typography>
									<Typography color="textSecondary">{paymentReversal.description}</Typography>
								</Grid>
							)}
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

function ReversePaymentDialog(props: {
	paymentTransaction: PaymentTransaction;
	customer: Customer;
	open: boolean;
	onClose: () => void;
	onConfirm: (reason: string | null, isChargeback: boolean) => void;
}) {
	const { open, paymentTransaction, customer, onClose, onConfirm } = props;

	const [reason, setReason] = useState("");
	const [isChargeback, setIsChargeback] = useState(false);

	const confirm = () => {
		const givenReason = reason.trim() === "" ? null : reason;
		onConfirm(givenReason, isChargeback);
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Reverse Payment</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">
					Are you sure you want to remove this payment?
					<br />
					{(paymentTransaction.type === "Card" || paymentTransaction.type === "Bank")  && (
						<FormControlLabel
							control={<Checkbox checked={isChargeback} onChange={(e) => setIsChargeback(e.target.checked)} color="primary" />}
							label="This charge has been disputed and is a chargeback"
						/>
					)}
					<br />- {customer.name}&apos;s balance will increase <Price value={paymentTransaction.amount} />
					<br />
					{paymentTransaction.type === "Card" && !isChargeback && (
						<>
							- The {paymentTransaction.cardDescription} will be refunded <Price value={paymentTransaction.amount} />
							<br />
						</>
					)}
					{paymentTransaction.type === "Bank" && !isChargeback && (
						<>
							- The {paymentTransaction.bankDescription} will be refunded <Price value={paymentTransaction.amount} />
							<br />
						</>
					)}
					{paymentTransaction.allocations.map((allocation, i) => (
						<React.Fragment key={i}>
							- A payment reversal of <Price value={allocation.amountApplied} /> will be applied to invoice {allocation.invoiceShortId}
							<br />
						</React.Fragment>
					))}
					{paymentTransaction.allocations.length > 0 &&
						"If you do not want a payment reversal to appear on an invoice, the payment must be removed from the invoice beforehand"}
				</DialogContentText>
				<TextField
					autoFocus
					variant="outlined"
					label="Reason"
					fullWidth
					value={reason}
					onChange={(e) => setReason(e.target.value)}
					helperText="(Optional) Reason for reversing the payment"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="secondary" variant="outlined">
					Cancel
				</Button>
				<Button onClick={confirm} color="secondary" variant="contained">
					Reverse Payment
				</Button>
			</DialogActions>
		</Dialog>
	);
}
