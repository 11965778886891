import { Box, Typography, List, ListItemText, ListItem, ListItemIcon, Divider, Button, CircularProgress, Grid, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useAlert } from "../../../../hooks/useAlert";
import ClearIcon from "@material-ui/icons/Clear";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { GridGrow } from "../../../../components/GridGrow";
import { AddPaymentMethodDialog } from "../../../payments/dialog/AddPaymentMethodDialog";
import { PaymentMethod, PaymentMethodType } from "../../../../entities/billing/PaymentMethod";
import { PaymentMethodService } from "../../../../services/billing/PaymentMethodService";
import { RedText } from "../../../../components/RedText";
import { useCustomerPaymentCardContext } from "./CustomerPaymentCard";
import { useAppUser } from "../../../../providers/AppUserProvider";
import { JwtUser } from "../../../../entities/auth/JwtUser";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

function availablePaymentMethods(user: JwtUser): PaymentMethodType[] {
	if (user.paymentProcessor === "dispatchpro") {
		return ["Card", "ACH"];
	} else if (user.paymentProcessor === "qualpay") {
		return ["Card"];
	}
	return [];
}

export function PaymentMethodSection() {
	const alert = useAlert();
	const context = useCustomerPaymentCardContext();
	const { customer, disabled, setDisabled } = context;
	const customerId = customer.id;
	const user = useAppUser();

	const [paymentTypes] = useState<PaymentMethodType[]>(availablePaymentMethods(user));
	const [addPaymentMethodDialog, setAddPaymentMethodDialog] = useState<"card" | "bank" | null>(null);
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();

	useEffect(() => {
		async function loadPaymentMethods() {
			const result = await PaymentMethodService.getByCustomer(customerId);
			if (result.success) {
				setPaymentMethods(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadPaymentMethods();
	}, [alert, customerId]);

	const onPaymentMethodAdded = (paymentMethod: PaymentMethod) => {
		if (!paymentMethods) {
			setPaymentMethods([paymentMethod]);
			return;
		}
		setPaymentMethods([...paymentMethods.map((p) => (p.isDefault ? { ...p, isDefault: false } : p)), paymentMethod]);
		setAddPaymentMethodDialog(null);

		alert.success("Payment method added");
	};

	const removePaymentMethod = async (paymentMethod: PaymentMethod) => {
		if (!paymentMethods) {
			return;
		}
		setDisabled(true);
		const result = await PaymentMethodService.remove(paymentMethod.id);
		setDisabled(false);

		if (result.success) {
			setPaymentMethods(paymentMethods.filter((p) => p.id !== paymentMethod.id));
		} else if (result.error) {
			alert.serverError(result);
		}
	};

	const setDefaultPaymentMethod = async (paymentMethod: PaymentMethod) => {
		setDisabled(true);
		const result = await PaymentMethodService.setDefault(paymentMethod.id);
		setDisabled(false);
		if (result.success) {
			alert.success(`Changed default payment method`);
			setPaymentMethods(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const noCreditCardsAdded = paymentMethods && paymentMethods.length === 0;

	if (paymentTypes.length === 0) {
		return null;
	}

	const typeText = paymentTypes.length > 1 ? "Payment Method" : "Credit Card";
	const achEnabled = paymentTypes.includes("ACH");

	return (
		<>
			<AddPaymentMethodDialog
				customer={customer}
				open={addPaymentMethodDialog != null}
				type={addPaymentMethodDialog ?? "card"}
				onClose={() => setAddPaymentMethodDialog(null)}
				onCreate={onPaymentMethodAdded}
			/>
			<Box pl={1} pr={2} pb={noCreditCardsAdded ? 1 : 0}>
				<Grid container spacing={1}>
					<Grid item>
						<Typography variant="overline">
							{noCreditCardsAdded && "No "}
							{typeText}s
						</Typography>
					</Grid>
					<GridGrow />
					{achEnabled && (
						<Grid item>
							<Button size="small" variant="outlined" onClick={() => setAddPaymentMethodDialog("bank")} disabled={disabled}>
								Add Bank Account
							</Button>
						</Grid>
					)}
					<Grid item>
						<Button size="small" variant="outlined" onClick={() => setAddPaymentMethodDialog("card")} disabled={disabled}>
							Add Credit Card
						</Button>
					</Grid>
				</Grid>
			</Box>
			{!paymentMethods && <CircularProgress size="1.5rem" color="secondary" />}
			{paymentMethods && paymentMethods.length > 0 && (
				<List dense style={{ padding: 0 }}>
					{paymentMethods.map((paymentMethod, i) => (
						<React.Fragment key={paymentMethod.id}>
							{i > 0 && <Divider component="li" />}
							<ListItem button onClick={() => setDefaultPaymentMethod(paymentMethod)} disabled={disabled}>
								{paymentMethod.type === "Card" && (
									<>
										<ListItemIcon color={paymentMethod.isExpired ? "error" : undefined}>
											<CreditCardIcon />
										</ListItemIcon>
										<ListItemText
											primary={paymentMethod.brand}
											secondary={
												<>
													***{paymentMethod.last4} Exp:{" "}
													{paymentMethod.isExpired ? (
														<RedText>
															{paymentMethod.expirationMonth}/{paymentMethod.expirationYear} [ Expired ]
														</RedText>
													) : (
														<>
															{paymentMethod.expirationMonth}/{paymentMethod.expirationYear}
														</>
													)}
												</>
											}
											style={{ margin: 0 }}
										/>
									</>
								)}
								{paymentMethod.type === "ACH" && (
									<>
										<ListItemIcon>
											<AccountBalanceIcon />
										</ListItemIcon>
										<ListItemText primary="Bank Account" secondary={paymentMethod.description} style={{ margin: 0 }} />
									</>
								)}
								{paymentMethod.isDefault && <ListItemText secondary="Default" />}
								<ListItemIcon>
									<IconButton
										disabled={disabled}
										onClick={(e) => {
											e.stopPropagation();
											removePaymentMethod(paymentMethod);
										}}
									>
										<ClearIcon />
									</IconButton>
								</ListItemIcon>
							</ListItem>
						</React.Fragment>
					))}
				</List>
			)}
		</>
	);
}
