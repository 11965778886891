import React, { useEffect } from "react";
import { CreditCardPaymentMethod } from "../../entities/billing/PaymentMethod";
import { FinixForm, FinixFormOptions, FinixJsClient } from "../../utility/FinixJsClient";
import { useAlert } from "../../hooks/useAlert";
import { runningOnProd } from "../../AppEnv";
import { PaymentMethodService } from "../../services/billing/PaymentMethodService";
import { Customer } from "../../entities/customer/Customer";
import { Button } from "@material-ui/core";
import OverlayLoadingScreen from "../OverlayLoadingScreen";

interface Props {
	customer: Customer;
	onClose: () => void;
	onCreate: (creditCard: CreditCardPaymentMethod) => void;
}

const formId = "dispatchpro-card-form";

export function DispatchProCardInput(props: Props) {
	const { customer } = props;
	const alert = useAlert();

	const [loading, setLoading] = React.useState(false);
	const [formHasErrors, setFormHasErrors] = React.useState(true);

	const finixForm = React.useRef<FinixForm | null>(null);

	useEffect(() => {
		const options: FinixFormOptions = {
			onUpdate(state, binInformation, formHasErrors) {
				setFormHasErrors(formHasErrors);
			},
			showAddress: true,
			hideFields: ["address_state", "address_country", "address_city", "address_region", "address_line2", "address_line1"],
			requiredFields: ["address_postal_code"],
			labels: {
				address_postal_code: "Billing Address Zip Code",
			},
		};
		const form = FinixJsClient.mountCardTokenForm(formId, options);
		finixForm.current = form;
	}, [alert]);

	const onFormSubmit = () => {
		const form = finixForm.current;
		if (!form) return;

		const prod = runningOnProd();
		const env = prod ? "live" : "sandbox";
		const applicationId = prod ? "APi9DbEiv5X6gqGx6ePt8pkj" : "AP5ePipiAXNbAPh42BCxr1mC";
		form.submit(env, applicationId, async function (err, res) {
			if (res.status === 422) {
				alert.error(res.data._embedded.errors[0].message);
				return;
			}
			if (err) {
				alert.error("An error occurred while encrypting the card information");
				return;
			}
            const token = res.data?.id;
			if (token) {
				setLoading(true);
                const result = await PaymentMethodService.addDispatchProCreditCard({
                    customerId: customer.id,
                    token,
                    isDefault: true
                });
                setLoading(false);
                if(result.success) {
                    props.onCreate(result.data);
                    alert.success("Card added successfully");
                } else if (result.validation) {
                    alert.validation(result);
                } else {
                    alert.serverError(result);
                }
			}
		});
	};


	return (
		<>
			{loading && <OverlayLoadingScreen />}
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onFormSubmit();
				}}
			>
				<div id={formId} />
				<Button type="submit" variant="contained" color="primary" disabled={loading || formHasErrors} fullWidth>
					Add Card
				</Button>
			</form>
		</>
	);
}
