import React from "react";
import { Customer } from "../../entities/customer/Customer";
import { BankAccountPaymentMethod } from "../../entities/billing/PaymentMethod";
import { DispatchProBankAccountForm } from "./DispatchProBankAccountForm";
import { PaymentMethodService } from "../../services/billing/PaymentMethodService";
import { useAlert } from "../../hooks/useAlert";

interface Props {
	customer: Customer;
	onClose: () => void;
	onCreate: (bankAccount: BankAccountPaymentMethod) => void;
}

export function DispatchProBankAccountInput(props: Props) {
	const alert = useAlert();
	const [loading, setLoading] = React.useState(false);

	const saveCustomerBankAccount = async (token: string) => {
		setLoading(true);
		const result = await PaymentMethodService.addDispatchProBankAccount({
			customerId: props.customer.id,
			token: token,
		});
		setLoading(false);
		if (result.success) {
			props.onCreate(result.data);
			props.onClose();
			alert.success("Bank account added successfully");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return <DispatchProBankAccountForm disabled={loading} onCancel={props.onClose} onSubmit={saveCustomerBankAccount} />;
}
