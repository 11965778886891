import React, { useEffect } from "react";
import { FinixForm, FinixJsClient } from "../../utility/FinixJsClient";
import { runningOnProd } from "../../AppEnv";
import { Grid, Button } from "@material-ui/core";
import { useAlert } from "../../hooks/useAlert";

const formId = "dispatchpro-bank-form";

type Props = React.PropsWithChildren<{
	onSubmit: (token: string) => void;
	disabled?: boolean;
	onCancel: () => void;
}>;

export function DispatchProBankAccountForm(props: Props) {
	const { disabled } = props;
	const finixForm = React.useRef<FinixForm | null>(null);
    const alert = useAlert();

	const [formHasErrors, setFormHasErrors] = React.useState(true);

	useEffect(() => {
		setTimeout(() => {
			const form = FinixJsClient.mountBankTokenForm(formId, {
				labels: {
					name: "Account Holder Name",
					bank_code: "Routing Number",
				},
				onUpdate: (_state, _binInformation, formHasErrors) => {
					setFormHasErrors(formHasErrors);
				},
			});
			finixForm.current = form;
		}, 700);
	}, []);

	const onFormSubmit = () => {
		const form = finixForm.current;
		if (!form) return;

		const env = runningOnProd() ? "live" : "sandbox";
		// const env ="live";
		const applicationId = env === "live" ? "APi9DbEiv5X6gqGx6ePt8pkj" : "AP5ePipiAXNbAPh42BCxr1mC";
		form.submit(env, applicationId, async (err, res) => {
			if (res.status === 422) {
				console.error(JSON.stringify(res));
				if(res.status === 422) {
					alert.error(res.data._embedded.errors[0].message);
				}
				else{
					alert.error("An error occurred while encrypting the bank account information");
				}				
				return;
			}
			const token = res.data.id;
			props.onSubmit(token);
		});
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onFormSubmit();
			}}
		>
			<div id={formId} />
			{props.children}
			<Grid container spacing={2}>
				<Grid item>
					<Button variant="outlined" onClick={props.onCancel} disabled={disabled}>
						Cancel
					</Button>
				</Grid>
				<Grid item>
					<Button variant="contained" color="primary" type="submit" disabled={disabled || formHasErrors}>
						Link Bank Account
					</Button>
				</Grid>
			</Grid>
		</form>
	);
}
