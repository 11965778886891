import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonLink } from "../../../components/ButtonLink";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { Price } from "../../../components/Price";
import { PriceTextField } from "../../../components/PriceTextField";
import { RedText } from "../../../components/RedText";
import { RestrictToRole } from "../../../components/RestrictToRole";
import { PaymentTransaction } from "../../../entities/accounting/PaymentTransaction";
import { Invoice, SearchableInvoice } from "../../../entities/billing/Invoice";
import { PaymentMethod } from "../../../entities/billing/PaymentMethod";
import { useAlert } from "../../../hooks/useAlert";
import { ApplyNewPaymentRequest, ApplyPaymentRequestBase, InvoiceService } from "../../../services/billing/InvoiceService";
import { PaymentMethodService, SurchargeConfiguration } from "../../../services/billing/PaymentMethodService";
import { Money } from "../../../utility/Money";
import { orderBy } from "../../../utility/orderBy";
import { PercentTextField } from "../../../components/PercentTextField";
import { useTenant } from "../../../providers/TenantProvider";
import { useLocalStorage } from "../../../utility/useLocalStorage";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { NodaTime } from "../../../utility/NodaTime";
import { FinixJsClient } from "../../../utility/FinixJsClient";
import { IntegrationService } from "../../../services/auth/IntegrationService";
import { DispatchProPaymentsIntegrationOption, IntegrationOption, IntegrationType } from "../../../entities/auth/IntegrationConfiguration";
import { runningOnProd } from "../../../AppEnv";

interface Props {
	open: boolean;
	customerId: string;
	onClose: () => void;
	onPaymentApplied: (invoices: Invoice[], paymentTransaction: PaymentTransaction) => void;
}

export function ApplyPaymentDialog(props: Props) {
	const { open, customerId } = props;

	const alert = useAlert();

	const [openInvoices, setOpenInvoices] = useState<SearchableInvoice[]>();
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
	const [surchargeConfiguration, setSurchargeConfiguration] = useState<SurchargeConfiguration>();

	useEffect(() => {
		async function fetchOpenInvoices() {
			const result = await InvoiceService.getCustomerInvoices(customerId);
			if (result.success) {
				const invoices = result.data.filter((i) => i.closed === false).sort(orderBy.date((i) => i.dueBy, "Ascending"));
				setOpenInvoices(invoices);
			} else {
				alert.serverError(result);
			}
		}

		if (open) {
			fetchOpenInvoices();
		} else {
			setOpenInvoices(undefined);
		}
	}, [open, customerId, alert]);

	useEffect(() => {
		async function fetchPaymentMethods() {
			const result = await PaymentMethodService.getByCustomer(customerId);
			if (result.success) {
				setPaymentMethods(result.data);
			} else {
				alert.serverError(result);
			}
		}

		if (open) {
			fetchPaymentMethods();
		} else {
			setPaymentMethods(undefined);
		}
	}, [open, customerId, alert]);

	useEffect(() => {
		async function fetchSurchargeConfiguration() {
			const result = await PaymentMethodService.getSurchargeConfiguration();
			if (result.success) {
				setSurchargeConfiguration(result.data);
			} else {
				alert.serverError(result);
			}
		}

		fetchSurchargeConfiguration();
	}, [alert]);

	return (
		<Dialog open={open} onClose={props.onClose} maxWidth="md" fullWidth>
			<DialogTitle>Apply Payment</DialogTitle>
			{(openInvoices === undefined || paymentMethods === undefined || surchargeConfiguration === undefined) && (
				<DialogContent>
					<CenteredLoadingSpinner />
				</DialogContent>
			)}
			{openInvoices !== undefined && paymentMethods !== undefined && surchargeConfiguration !== undefined && (
				<PaymentDialogContent
					openInvoices={openInvoices}
					paymentMethods={paymentMethods}
					customerId={customerId}
					surchargeConfiguration={surchargeConfiguration}
					onClose={props.onClose}
					onPaymentApplied={props.onPaymentApplied}
				/>
			)}
		</Dialog>
	);
}

interface PaymentDialogContentProps {
	customerId: string;
	openInvoices: SearchableInvoice[];
	paymentMethods: PaymentMethod[];
	surchargeConfiguration: SurchargeConfiguration;
	onClose: () => void;
	onPaymentApplied: (invoices: Invoice[], paymentTransaction: PaymentTransaction) => void;
}

type PaymentType = "cash" | "ach" | "check" | "card";

function getSessionId(paymentMethod: PaymentMethod, integrations: IntegrationOption[], onSessionKey: (sessionKey:string) => void) {
	if(paymentMethod.processor !== "DispatchPro") return null;
	const integration = integrations.find(i => i.type === IntegrationType.DispatchProPayments) as DispatchProPaymentsIntegrationOption | undefined;
	if(!integration) return null;
	const merchantId = integration.configuration?.merchantId;
	if(!merchantId) return null;
	const prod = runningOnProd();
	const env = prod ? "live" : "sandbox";
	FinixJsClient.getSessionKey(env, merchantId, onSessionKey);
}


function PaymentDialogContent(props: PaymentDialogContentProps) {
	const { openInvoices, paymentMethods, surchargeConfiguration, customerId } = props;

	const alert = useAlert();

	const defaultPaymentMethod = paymentMethods.find((p) => p.isDefault);

	const [inputAmount, setInputAmount] = useState<number| null>(null);
	const [paymentType, setPaymentType] = useState<PaymentType>(defaultPaymentMethod ? (defaultPaymentMethod.type === "ACH" ? "ach" : "card") : "check");
	const [paymentMethodId, setPaymentMethodId] = useState(defaultPaymentMethod?.id);
	const [checkNumber, setCheckNumber] = useState("");
	const [receivedOnDate, setReceivedOnDate] = useState<Date | null>(null);
	const [surchargePercentage, setSurchargePercentage] = useState<number | null>(surchargeConfiguration.defaultPercentage / 100);

	const [appliesTo, setAppliesTo] = useState<{ invoice: SearchableInvoice; amountApplied: Dinero.Dinero }[]>([]);
	const [disabled, setDisabled] = useState(false);
	const [showLargePaymentConfirmation, setShowLargePaymentConfirmation] = useState(false);
	const [showUnallocatedPaymentConfirmation, setShowUnallocatedPaymentConfirmation] = useState(false);
	const [sessionId, setSessionId] = useState<string | null>(null);
	const [showSurchargeDisclosure, setShowSurchargeDisclosure] = useLocalStorage("showSurchargeDisclosure", false);

	const [integrations, setIntegrations] = useState<IntegrationOption[]>([]);

	const paymentMethod = useMemo(() => paymentMethods.find((p) => p.id === paymentMethodId), [paymentMethodId, paymentMethods]);

	const amount = useMemo(() => Money.fromDecimal(inputAmount ?? 0), [inputAmount]);
	const surchargeAmount = useMemo(() => amount.multiply(surchargePercentage ?? 0), [amount, surchargePercentage]);
	const surchargeTooLarge = surchargePercentage != null && surchargePercentage > surchargeConfiguration.maxPercentage / 100;

	const outstandingBalance = useMemo(() => openInvoices.reduce((acc, i) => acc.add(i.balance), Money.zero), [openInvoices]);
	const anyInvoicesOverpaid = useMemo(() => appliesTo.some((a) => a.amountApplied.greaterThan(a.invoice.total)), [appliesTo]);
	const totalAppliedToInvoices = useMemo(() => appliesTo.reduce((acc, curr) => acc.add(curr.amountApplied), Money.zero), [appliesTo]);
	const unallocatedAmount = Money.max(amount.subtract(totalAppliedToInvoices), Money.zero);
	const remainingBalance = outstandingBalance.subtract(totalAppliedToInvoices);
	const excessAppliedAmount = Money.max(totalAppliedToInvoices.subtract(amount), Money.zero);

	const disableSubmit = anyInvoicesOverpaid || Money.isPositive(excessAppliedAmount) || amount.isZero() || disabled || (paymentType === "card" && surchargeTooLarge);

	useEffect(() => {
		async function fetchIntegrations() {
			const result = await IntegrationService.getIntegrationOptions();
			if (result.success) {
				setIntegrations(result.data);
			} else {
				alert.serverError(result);
			}
		}
		fetchIntegrations();
	}, [alert]);

	useEffect(() => {
		if(paymentMethod && sessionId === null) {
			getSessionId(paymentMethod, integrations, setSessionId);
		}
	}, [paymentMethod, integrations, sessionId]);


	useEffect(() => {
		setAppliesTo(openInvoices.map((invoice) => ({ invoice, amountApplied: Money.zero })));
	}, [openInvoices]);

	const onAppliedAmountChange = (amount: Dinero.Dinero, invoiceId: string) => {
		const updatedAppliedTo = appliesTo.map((a) => {
			if (a.invoice.id === invoiceId) {
				return { ...a, amountApplied: amount };
			}
			return a;
		});
		setAppliesTo(updatedAppliedTo);
	};

	const onPaymentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		if (value === "cash" || value === "check") {
			setPaymentType(value);
			setPaymentMethodId(undefined);
		} else {
			setPaymentType("card");
			setPaymentMethodId(value);
		}
	};

	const onPaymentAmountChange = (updatedInputAmount: number | null) => {
		setInputAmount(updatedInputAmount);
		const updatedAmount = Money.fromDecimal(updatedInputAmount ?? 0);

		if (updatedAmount.equalsTo(outstandingBalance)) {
			setAppliesTo(openInvoices.map((invoice) => ({ invoice, amountApplied: invoice.balance })));
			return;
		}

		const exactMatch = appliesTo.find((a) => a.invoice.balance.equalsTo(updatedAmount));
		if (exactMatch) {
			setAppliesTo(
				appliesTo.map((a) => {
					if (a.invoice.id === exactMatch.invoice.id) {
						return { ...a, amountApplied: updatedAmount };
					}
					return { ...a, amountApplied: Money.zero };
				})
			);
			return;
		}

		setAppliesTo(appliesTo.map((a) => ({ ...a, amountApplied: Money.zero })));
	};

	const getApplyPaymentRequest = (): ApplyNewPaymentRequest => {
		const request: ApplyPaymentRequestBase = {
			customerId,
			amount: amount.toRoundedUnit(2),
			appliesTo: appliesTo.map((a) => ({ amountApplied: a.amountApplied.toRoundedUnit(2), invoiceId: a.invoice.id })),
		};


		if (paymentType === "check") {
			return {
				...request,
				paymentType: "check",
				checkNumber,
				receivedOnDate: receivedOnDate ? NodaTime.serializeToLocalDate(receivedOnDate) : null,
			};
		}

		if (paymentType === "card" && paymentMethod) {
			return {
				...request,
				sessionId,
				paymentType: "card",
				paymentMethodId: paymentMethod.id,
				surchargeAmount: surchargeAmount.toRoundedUnit(2),
			};
		}

		if (paymentType === "ach" && paymentMethod) {
			return {
				...request,
				sessionId,
				paymentType: "ach",
				paymentMethodId: paymentMethod.id,
			};
		}

		return {
			...request,
			paymentType: "cash",
			receivedOnDate: receivedOnDate ? NodaTime.serializeToLocalDate(receivedOnDate) : null,
		};
	};

	const applyPayment = async () => {
		if (disableSubmit) {
			return;
		}

		setDisabled(true);
		const request = getApplyPaymentRequest();
		const result = await InvoiceService.applyNewPayment(request);
		setDisabled(false);

		if (result.success) {
			alert.success("Payment successfully applied");
			props.onPaymentApplied(result.data.invoices, result.data.paymentTransaction);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onSubmit = () => {
		if (inputAmount && inputAmount >= 5000) {
			setShowLargePaymentConfirmation(true);
		} else if (unallocatedAmount.greaterThan(Money.zero) && remainingBalance.greaterThan(Money.zero)) {
			setShowUnallocatedPaymentConfirmation(true);
		} else {
			applyPayment();
		}
	};

	const cardSurchargeText = () => {
		if (!surchargeConfiguration.enabled) return "Surcharge has not been enabled";
		if (surchargeTooLarge) return `Surcharge cannot be greater than ${surchargeConfiguration.maxPercentage}%`;
		return (
			<>
				Charge <Price value={surchargeAmount} /> extra as a surcharge.{" "}
				<ButtonLink onClick={() => setShowSurchargeDisclosure(!showSurchargeDisclosure)}>
					{showSurchargeDisclosure ? "(Hide Disclosure)" : "(Show Disclosure)"}
				</ButtonLink>
			</>
		);
	};

	return (
		<>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item md={3} sm={6} xs={12}>
						<TextField
							select
							variant="outlined"
							label="Payment Type"
							value={paymentMethodId ?? paymentType}
							disabled={disabled}
							onChange={onPaymentTypeChange}
							fullWidth
						>
							<MenuItem value="cash">Cash</MenuItem>
							<MenuItem value="check">Check</MenuItem>
							{paymentMethods.map((method) => {
								if(method.type === "ACH") {
									return (
										<MenuItem key={method.id} value={method.id}>
											ACH - Bank ****{method.last4}
										</MenuItem>
									);
								}

								return (
									<MenuItem key={method.id} value={method.id}>
										{method.description}
									</MenuItem>
								);
							})}
						</TextField>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<PriceTextField
							label="Payment Amount"
							value={inputAmount}
							variant="outlined"
							fullWidth
							autoFocus
							disabled={disabled}
							onPriceChanged={onPaymentAmountChange}
							helperText={`Amount received via ${paymentType}`}
						/>
					</Grid>
					{paymentType === "check" && (
						<Grid item md={3} sm={6} xs={12}>
							<TextField
								label="Check Number"
								variant="outlined"
								value={checkNumber}
								disabled={disabled}
								onChange={(e) => setCheckNumber(e.target.value)}
								helperText="Optional"
								fullWidth
							/>
						</Grid>
					)}
					{paymentType === "card" && (
						<Grid item md={3} sm={6} xs={12}>
							<PercentTextField
								label="Surcharge Amount"
								variant="outlined"
								onPercentChange={(percent) => setSurchargePercentage(percent)}
								value={surchargePercentage}
								disabled={disabled || !surchargeConfiguration.enabled}
								helperText={cardSurchargeText()}
								error={surchargeTooLarge}
								fullWidth
							/>
						</Grid>
					)}
					{paymentType !== "card" && paymentType !== "ach" && (
						<Grid item md={3} sm={6} xs={12}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								label="Received On"
								inputVariant="outlined"
								fullWidth
								value={receivedOnDate}
								disabled={disabled}
								maxDate={new Date()}
								maxDateMessage="Received on date cannot be in the future"
								onChange={setReceivedOnDate}
								helperText="Optional"
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						{paymentType === "card" && surchargePercentage != null && surchargePercentage > 0 && (
							<>
								{showSurchargeDisclosure && <SurchargeDisclosure surchargePercentage={surchargePercentage} />}
								<Typography variant="body1" align="center">
									Amount to be charged to card: <Price value={amount.add(surchargeAmount)} />
								</Typography>
							</>
						)}
						<Typography variant="body1" align="center">
							Total applied to invoices: <Price value={totalAppliedToInvoices} />{" "}
							{!excessAppliedAmount.isZero() && (
								<RedText>
									Excess applied amount: <Price value={excessAppliedAmount} />
								</RedText>
							)}
						</Typography>
						<Paper>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Invoice</TableCell>
											<TableCell>Invoice Date</TableCell>
											<TableCell>Due Date</TableCell>
											<TableCell>Balance</TableCell>
											<TableCell>Amount Applied</TableCell>
											<TableCell>Remaining</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{appliesTo.map((applyTo) => (
											<InvoiceTableRow
												invoice={applyTo.invoice}
												amountApplied={applyTo.amountApplied}
												totalAmountRemaining={unallocatedAmount}
												onChange={onAppliedAmountChange}
												key={applyTo.invoice.id}
											/>
										))}
										<TableRow>
											<TableCell align="right" colSpan={5}>
												Extra amount to apply to account
											</TableCell>
											<TableCell>
												<Price value={unallocatedAmount} />
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button disabled={disabled} onClick={props.onClose} variant="outlined" color="secondary">
					Cancel
				</Button>
				<RestrictToRole admin accounting>
					<Button onClick={onSubmit} disabled={disableSubmit} variant="contained" color="primary">
						Apply Payment
					</Button>
				</RestrictToRole>
			</DialogActions>
			<ConfirmLargePaymentDialog open={showLargePaymentConfirmation} amount={amount} onClose={() => setShowLargePaymentConfirmation(false)} onConfirm={applyPayment} />
			<ConfirmUnallocatedPaymentDialog
				open={showUnallocatedPaymentConfirmation}
				remainingBalance={remainingBalance}
				unallocatedAmount={unallocatedAmount}
				onClose={() => setShowUnallocatedPaymentConfirmation(false)}
				onConfirm={applyPayment}
			/>
		</>
	);
}

function InvoiceTableRow(props: {
	invoice: SearchableInvoice;
	amountApplied: Dinero.Dinero;
	totalAmountRemaining: Dinero.Dinero;
	onChange: (amountApplied: Dinero.Dinero, invoiceId: string) => void;
}) {
	const { invoice, amountApplied, totalAmountRemaining } = props;
	const { id: invoiceId, shortId, issuedOn, dueBy } = invoice;
	const balance = invoice.balance;

	const [editMode, setEditMode] = useState(false);

	const amountToApply = Money.min(balance, totalAmountRemaining.add(amountApplied));
	const amountRemaining = Money.max(balance.subtract(amountApplied), Money.zero);
	const excessAmount = Money.max(amountApplied.subtract(balance), Money.zero);

	const onPriceChanged = (priceInputValue: number | null) => {
		if (priceInputValue === null) {
			return props.onChange(Money.zero, invoiceId);
		}
		return props.onChange(Money.fromDecimal(priceInputValue), invoiceId);
	};

	return (
		<TableRow>
			<TableCell>{shortId}</TableCell>
			<TableCell>{issuedOn.toLocaleDateString()}</TableCell>
			<TableCell>{dueBy.toLocaleDateString()}</TableCell>
			<TableCell>
				<Price value={balance} />
			</TableCell>
			<TableCell>
				{editMode ? (
					<PriceTextField
						autoFocus
						variant="outlined"
						size="small"
						value={amountApplied.toRoundedUnit(2)}
						onPriceChanged={onPriceChanged}
						error={!excessAmount.isZero()}
						helperText={!excessAmount.isZero() ? `Applied ${excessAmount.toRoundedUnit(2)} more than balance` : ""}
						style={{ maxWidth: 150 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => setEditMode(false)} size="small">
										<Close />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				) : (
					<Grid container alignItems="center">
						<Grid item>
							<Box minWidth={70}>
								<ButtonLink onClick={() => setEditMode(true)}>
									<Price value={amountApplied} />
								</ButtonLink>
							</Box>
						</Grid>
						<Grid item>
							<Button size="small" onClick={() => props.onChange(amountToApply, invoiceId)}>
								Apply
							</Button>
							<Button size="small" onClick={() => props.onChange(Money.zero, invoiceId)}>
								Clear
							</Button>
						</Grid>
					</Grid>
				)}
			</TableCell>
			<TableCell>
				<Price value={amountRemaining} />
			</TableCell>
		</TableRow>
	);
}

function ConfirmLargePaymentDialog(props: { open: boolean; amount: Dinero.Dinero; onClose: () => void; onConfirm: () => void }) {
	const { amount } = props;
	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>Confirm Large Payment Amount</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure you want to apply a payment of{" "}
					<Box display="inline" fontSize={22}>
						<Price value={amount} />
					</Box>
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} variant="outlined" color="secondary">
					Cancel
				</Button>
				<Button onClick={props.onConfirm} variant="contained" color="primary">
					Apply Large Payment
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function ConfirmUnallocatedPaymentDialog(props: {
	open: boolean;
	unallocatedAmount: Dinero.Dinero;
	remainingBalance: Dinero.Dinero;
	onClose: () => void;
	onConfirm: () => void;
}) {
	const { remainingBalance, unallocatedAmount } = props;
	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>Confirm Unallocated Payment</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure you want to leave{" "}
					<Box display="inline" fontSize={19}>
						<Price value={unallocatedAmount} />
					</Box>{" "}
					unallocated?
				</Typography>
				<Typography>
					This payment can still be applied to{" "}
					<Box display="inline" fontSize={19}>
						<Price value={remainingBalance} />
					</Box>{" "}
					in outstanding balances.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} variant="outlined" color="secondary">
					Cancel
				</Button>
				<Button onClick={props.onConfirm} variant="contained" color="primary">
					Confirm Allocation
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function SurchargeDisclosure(props: { surchargePercentage: number }) {
	const { tenant } = useTenant();
	const { officeAddress } = tenant;
	const state = officeAddress?.state ?? "Unknown";

	if (state === "CO") {
		return (
			<>
				<Typography variant="body2">
					Colorado Surcharge Disclosure <Link href="https://leg.colorado.gov/sites/default/files/2021a_091_signed.pdf">(See Senate Bill 21-091)</Link>
				</Typography>
				<Typography variant="body2" color="textSecondary">
					To cover the cost of processing a credit or charge card transaction, and pursuant to Section 5-2-212, Colorado Revised Statutes, a seller or lessor may
					impose a processing surcharge in an amount not to exceed 2% of the total payment made for goods or services purchased or leased by use of a credit or
					charge card. A seller or lessor shall not impose a processing surcharge on payments made by use of cash, a check, or a debit card or redemption of a gift
					card.
				</Typography>
			</>
		);
	}

	return (
		<>
			<Typography variant="body2">Surcharge Disclosure</Typography>
			<Typography variant="body2" color="textSecondary">
				We impose a surcharge of {props.surchargePercentage * 100}% on the transaction amount when a credit card is used. This amount is not greater than our cost of
				acceptance, however if you would like to pay by check, cash, or Debit Card, there is no fee associated.
			</Typography>
		</>
	);
}
