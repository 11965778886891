import { Dialog } from "@material-ui/core";
import React from "react";
import { ManualAddress } from "../../entities/customer/ManualAddress";
import { ManualAddressFormView } from "./ManualAddressFormView";
import { GeocodedAddress } from "../../entities/customer/GeocodedAddress";

interface ManualAddressDialogProps {
	open: boolean;
	address?: GeocodedAddress;
	onClose: () => void;
	onSubmit: (address: ManualAddress) => void;
}

export function ManualAddressDialog(props: ManualAddressDialogProps) {
	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth={"lg"}>
			<ManualAddressFormView
				onCancel={props.onClose}
				onSubmit={(a) => {
					props.onSubmit(a);
					console.log("onSubmitManual", a);
					props.onClose();
				}}
				address={props.address}
			/>
		</Dialog>
	);
}
