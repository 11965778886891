type HidableFields =
	| "name"
	| "number"
	| "expiration_date"
	| "security_code"
	| "address_line1"
	| "address_line2"
	| "address_city"
	| "address_state"
	| "address_region"
	| "address_country"
	| "address_postal_code";
type RequiredFields = "name" | "address_line1" | "address_line2" | "address_city" | "address_state" | "address_region" | "address_country" | "address_postal_code";

/**
 * Interface representing the options for input fields in the form.
 */
export interface FinixFormOptions {
	/**
	 * Show address fields in the form (default is false).
	 */
	showAddress?: boolean;

	/**
	 * Show labels in the form (default is true).
	 */
	showLabels?: boolean;

	/**
	 * Set custom labels for each field.
	 */
	labels?: {
		/**
		 * Custom label for the name field.
		 */
		name?: string;

		/**
		 * Custom label for the number field.
		 */
		number?: string;

		/**
		 * Custom label for the expiration date field.
		 */
		expiration_date?: string;

		/**
		 * Custom label for the security code field.
		 */
		security_code?: string;

		/**
		 * Custom label for the address line 1 field.
		 */
		address_line1?: string;

		/**
		 * Custom label for the address line 2 field.
		 */
		address_line2?: string;

		/**
		 * Custom label for the address city field.
		 */
		address_city?: string;

		/**
		 * Custom label for the address state field.
		 */
		address_state?: string;

		/**
		 * Custom label for the address region field.
		 */
		address_region?: string;

		/**
		 * Custom label for the address country field.
		 */
		address_country?: string;

		/**
		 * Custom label for the address postal code field.
		 */
		address_postal_code?: string;

		/**
		 * Custom label for the account number field.
		 */
		account_number?: string;

		/**
		 * Custom label for the bank code field.
		 */
		bank_code?: string;

		/**
		 * Custom label for the account type field.
		 */
		account_type?: string;
	};

	/**
	 * Turn on or off placeholder text in the fields (default is true).
	 */
	showPlaceholders?: boolean;

	/**
	 * Set custom placeholders for each field.
	 */
	placeholders?: {
		/**
		 * Custom placeholder for the name field.
		 */
		name?: string;

		/**
		 * Custom placeholder for the number field.
		 */
		number?: string;

		/**
		 * Custom placeholder for the expiration date field.
		 */
		expiration_date?: string;

		/**
		 * Custom placeholder for the security code field.
		 */
		security_code?: string;

		/**
		 * Custom placeholder for the address line 1 field.
		 */
		address_line1?: string;

		/**
		 * Custom placeholder for the address line 2 field.
		 */
		address_line2?: string;

		/**
		 * Custom placeholder for the address city field.
		 */
		address_city?: string;

		/**
		 * Custom placeholder for the address state field.
		 */
		address_state?: string;

		/**
		 * Custom placeholder for the address region field.
		 */
		address_region?: string;

		/**
		 * Custom placeholder for the address country field.
		 */
		address_country?: string;

		/**
		 * Custom placeholder for the address postal code field.
		 */
		address_postal_code?: string;

		/**
		 * Custom placeholder for the account number field.
		 */
		account_number?: string;

		/**
		 * Custom placeholder for the bank code field.
		 */

		bank_code?: string;

		/**
		 * Custom placeholder for the account type field.
		 */
		account_type?: string;
	};

	/**
	 * Hide specific fields.
	 */
	hideFields?: HidableFields[];

	/**
	 * Require specific fields that are not required by default.
	 */
	requiredFields?: RequiredFields[];

	/**
	 * Hide error messages.
	 */
	hideErrorMessages?: boolean;

	/**
	 * Set custom error messages for each field if you are showing error messages.
	 */
	errorMessages?: {
		/**
		 * Custom error message for the name field.
		 */
		name?: string;

		/**
		 * Custom error message for the number field.
		 */
		number?: string;

		/**
		 * Custom error message for the expiration date field.
		 */
		expiration_date?: string;

		/**
		 * Custom error message for the security code field.
		 */
		security_code?: string;

		/**
		 * Custom error message for the address line 1 field.
		 */
		address_line1?: string;

		/**
		 * Custom error message for the address line 2 field.
		 */
		address_line2?: string;

		/**
		 * Custom error message for the address city field.
		 */
		address_city?: string;

		/**
		 * Custom error message for the address state field.
		 */
		address_state?: string;

		/**
		 * Custom error message for the address region field.
		 */
		address_region?: string;

		/**
		 * Custom error message for the address country field.
		 */
		address_country?: string;

		/**
		 * Custom error message for the address postal code field.
		 */
		address_postal_code?: string;

		/**
		 * Custom error message for the account number field.
		 */
		account_number?: string;

		/**
		 * Custom error message for the bank code field.
		 */
		bank_code?: string;

		/**
		 * Custom error message for the account type field.
		 */
		account_type?: string;
	};
	/**
	 *  Custom styles for the form inputs (optional but recommended)
	 */
	styles?: {
		/**
		 * Default styling for all fields
		 */
		default?: {
			color?: string;
			border?: string;
			borderRadius?: string;
			padding?: string;
			fontFamily?: string;
			fontSize?: string;
			boxShadow?: string;
		};
		/**
		 * Specific styling if the field is valid
		 */
		success?: {
			color?: string;
		};
		/**
		 * Specific styling if the field has errors
		 */
		error?: {
			color?: string;
			border?: string;
		};
	};

	/**
	 * Optional callback function that will trigger when form state changes (can be called frequently)
	 */
	onUpdate?: (state: unknown, binInformation: unknown, formHasErrors: boolean) => void;
	/**
	 * Optional callback function that will trigger after the form has loaded
	 */
	onLoad?: () => void;
	/**
	 * Optional callback function that will be called when the form is submitted
	 * NOTE: adding this option will automatically create a submit button for you.
	 * If you do not want to use the default button and create your own,
	 * do not supply this function and instead create your own submit button
	 * and attach the onSubmit function to it manually.
	 */
	onSubmit?: () => void;
	/**
	 * Optional param to set the label for the submit button that is auto generated
	 */
	submitLabel?: string;
}

interface FormSubmitSuccess {
	data: {
		created_at: string;
		currency: string;
		expires_at: string;
		fingerprint: string;
		id: string;
		instrument_type: string;
		updated_at: string;
	}
	status: 200;
}

interface FormSubmitError {
	data: {
		total: number;
		_embedded: {
			errors: {
				message: string;
				code: string;
			}[];
		}
	}
	status: 422;
}

type FormSubmitResult = FormSubmitSuccess | FormSubmitError;



export interface FinixForm {
	submit: (environment: "sandbox" | "live", applicationId: string, callback: (error: unknown | null, result: FormSubmitResult) => void) => void;
}

/**
 * The global Finix object
 */
declare const Finix: {
	CardTokenForm: (formId: string, options?: FinixFormOptions) => FinixForm;
	BankTokenForm: (formId: string, options?: FinixFormOptions) => FinixForm;
	TokenForm: (formId: string, options?: FinixFormOptions) => FinixForm;
	Auth: (environment: "sandbox" | "live", merchantId: string, onSessionKeyReceived: (sessionKey: string) => void) => {
		getSessionKey: () => string;
	};
};


export const FinixJsClient = {
	/**
	 * Mounts a card form to the specified form element.
	 * @param formId The id of the form element to mount the form to.
	 * @param options The options for the form.
	 * @returns a FinixForm object.
	 */
	mountCardTokenForm: (formId: string, options?: FinixFormOptions) => Finix.CardTokenForm(formId, options),
	/**
	 * Mounts a bank form to the specified form element.
	 * @param formId The id of the form element to mount the form to.
	 * @param options The options for the form.
	 * @returns a FinixForm object.
	 */
	mountBankTokenForm: (formId: string, options?: FinixFormOptions) => Finix.BankTokenForm(formId, options),
	/**
	 * Mounts a token form to the specified form element. Supports both card and bank forms.
	 * @param formId The id of the form element to mount the form to.
	 * @param options The options for the form.
	 * @returns a FinixForm object.
	 */
	mountTokenForm: (formId: string, options?: FinixFormOptions) => Finix.TokenForm(formId, options),
	/**
	 * Get the session key from the FinixAuth object.
	 * @returns the session key.
	 */
	getSessionKey: (environment: "sandbox" | "live", merchantId: string, onSessionKeyReceived: (sessionKey: string) => void) => Finix.Auth(environment, merchantId, onSessionKeyReceived),

};
