import { DialogContent, Grid, TextField, FormControl, FormHelperText, DialogActions, Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { PhoneTextField } from "../../../components/PhoneTextField";
import { Customer } from "../../../entities/customer/Customer";
import { GeocodedAddress } from "../../../entities/customer/GeocodedAddress";
import { PhoneNumber } from "../../../entities/customer/PhoneNumber";
import { useAlert } from "../../../hooks/useAlert";
import { useTenant } from "../../../providers/TenantProvider";
import { CustomerService } from "../../../services/customer/CustomerService";
import { PropaneTankService } from "../../../services/customer/PropaneTankService";
import { FieldValidationError, validationError } from "../../../services/server/ServerValidationError";
import { NumberTextField } from "../../../components/NumberTextField";
import { AddressLookupField } from "../../../components/address/AddressLookupField";

interface Props {
	onCancel: () => void;
	onCustomerCreated: (customer: Customer) => void;
}

export function CreateCustomerDialogView(props: Props) {
	const alert = useAlert();
	const { tenant } = useTenant();
	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
	const [disabled, setDisabled] = useState(false);

	const [name, setName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | null>(tenant.areaCode ? { number: tenant.areaCode, extension: null } : null);
	const [gallons, setGallons] = useState<number | null>(null);
	const [address, setAddress] = useState<GeocodedAddress | undefined>();

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!name || !address) {
			return;
		}

		setDisabled(true);
		const customerResult = await CustomerService.create({
			name,
			phoneNumber: phoneNumber?.number ?? null,
			phoneNumberExtension: phoneNumber?.extension ?? null,
			address,
		});
		setDisabled(false);
		if (customerResult.validation) {
			setValidationErrors(customerResult.errors);
			alert.validation(customerResult);
			return;
		}
		if (customerResult.error) {
			alert.serverError(customerResult);
			return;
		}

		if (gallons == null || gallons <= 0) {
			props.onCustomerCreated(customerResult.data);
			return;
		}

		await PropaneTankService.create({
			customerId: customerResult.data.id,
			gallons: gallons,
			address: customerResult.data.address,
			isRental: false,
			locationDescription: null,
			serialNumber: null,
		});
		setDisabled(false);
		// If this goes bad they can always add tank later
		props.onCustomerCreated(customerResult.data);
	};

	const canSubmit = name.trim().length > 0 && address !== undefined;

	return (
		<form onSubmit={onSubmit}>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item lg={6} xs={12}>
						<TextField
							required
							variant="outlined"
							role="presentation"
							autoComplete="off"
							fullWidth
							disabled={disabled}
							label="Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							error={validationError.isFieldInError(validationErrors, "Name")}
							helperText={validationError.getFieldError(validationErrors, "Name")}
						/>
					</Grid>
					<Grid item lg={6} xs={12}>
						<PhoneTextField
							fullWidth
							variant="outlined"
							disabled={disabled}
							label="Phone"
							allowExtensions={true}
							value={phoneNumber}
							onChangePhoneNumber={(p) => setPhoneNumber(p)}
							error={validationError.isFieldInError(validationErrors, "PhoneNumber")}
							helperText={validationError.getFieldError(validationErrors, "PhoneNumber")}
						/>
					</Grid>
					<Grid item xs={12}>
						<AddressLookupField
							onSelect={setAddress}
							disabled={disabled}
							fullWidth
							required
							error={validationError.isFieldInError(validationErrors, "Address")}
							helperText={validationError.getFieldError(validationErrors, "Address")}
							placeholder="Address"
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2" color="textSecondary" style={{ marginBottom: 12 }}>
							Create With Tank (Optional)
						</Typography>

						<FormControl fullWidth disabled={disabled} error={validationError.isFieldInError(validationErrors, "Gallons")}>
							<NumberTextField
								label="Tank Capacity"
								variant="outlined"
								value={gallons}
								onNumberChange={(n) => setGallons(n)}
								fullWidth
								disabled={disabled}
								InputProps={{
									endAdornment: "gal",
								}}
								helperText="Total capacity of the tank (not ullage)"
							/>
							<FormHelperText>{validationError.getFieldError(validationErrors, "Gallons")}</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button disabled={disabled} onClick={props.onCancel} variant="outlined" color="secondary">
					Cancel
				</Button>
				<Button disabled={!canSubmit || disabled} type="submit" variant="contained" color="primary">
					Add
				</Button>
			</DialogActions>
		</form>
	);
}
