import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Button } from "@material-ui/core";
import React, { useMemo } from "react";

import { useHistory } from "react-router-dom";
import { routes } from "../../../../routes";
import { PaymentTransaction } from "../../../../entities/accounting/PaymentTransaction";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { RedText } from "../../../../components/RedText";
import { Price } from "../../../../components/Price";
import { orderBy } from "../../../../utility/orderBy";
import { useCustomerPaymentCardContext } from "./CustomerPaymentCard";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

export function PaymentsSection() {
	const history = useHistory();
	const context = useCustomerPaymentCardContext();
	const { customer, paymentTransactions, disabled } = context;

	const displayTransactions = useMemo(() => {
		if (!paymentTransactions) return [];
		const orderedTransactions = paymentTransactions.sort(orderBy.date((t) => t.created, "Descending"));
		const unallocatedPayments = orderedTransactions.filter((t) => t.unallocatedAmount > 0);
		const paymentsThatCanStillFit = 3 - unallocatedPayments.length;
		if (paymentsThatCanStillFit <= 0) return unallocatedPayments;
		return unallocatedPayments
			.concat(orderedTransactions.filter((t) => t.unallocatedAmount === 0).slice(0, paymentsThatCanStillFit))
			.sort(orderBy.date((t) => t.created, "Descending"));
	}, [paymentTransactions]);

	const viewAllPayments = () => history.push(routes.app.resolve.paymentsPage(customer.customerCode ?? undefined));
	const viewPayment = (paymentTransaction: PaymentTransaction) => history.push(routes.app.resolve.paymentDetailPage(paymentTransaction.id));

	const secondaryText = (paymentTransaction: PaymentTransaction) => {
		if (paymentTransaction.reversalTransactionId != null) {
			return <RedText>Reversed</RedText>;
		}

		if (paymentTransaction.unallocatedAmount > 0) {
			return (
				<>
					<Price value={paymentTransaction.unallocatedAmount} /> not applied to invoices yet
				</>
			);
		}

		return null;
	};

	const titleText = () => {
		if (!paymentTransactions) return "Loading Payments...";
		if (paymentTransactions && paymentTransactions.length === 0) return "No Payments Yet";
		return <>Recent Payments</>;
	};

	return (
		<>
			<Box pl={1}>
				<Typography variant="overline">{titleText()}</Typography>
			</Box>
			<List dense style={{ padding: 0 }}>
				{displayTransactions.map((paymentTransaction) => (
					<ListItem button key={paymentTransaction.id} onClick={() => viewPayment(paymentTransaction)} disabled={disabled}>
						<ListItemIcon>
							<PaymentIcon paymentTransaction={paymentTransaction} />
						</ListItemIcon>
						<ListItemText primary={paymentTransaction.description} secondary={secondaryText(paymentTransaction)} />
						<ListItemText secondary={paymentTransaction.created.toLocaleDateString()} style={{ paddingLeft: 6 }} secondaryTypographyProps={{ align: "right" }} />
					</ListItem>
				))}
			</List>
			<Button variant="outlined" size="small" style={{ marginLeft: 15 }} disabled={disabled} onClick={viewAllPayments}>
				View All Payments
			</Button>
		</>
	);
}

function PaymentIcon(props: { paymentTransaction: PaymentTransaction }) {
	const { paymentTransaction } = props;

	switch (paymentTransaction.type) {
		case "Cash":
			return <LocalAtmIcon />;
		case "Check":
			return <SubtitlesIcon />;
		case "Card":
			return <CreditCardIcon />;
		case "Bank":
			return <AccountBalanceIcon />;
	}
}
