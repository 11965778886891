import { Button, Typography } from "@material-ui/core";
import React from "react";
import { BankAccountPaymentMethod } from "../../entities/billing/PaymentMethod";
import { Customer } from "../../entities/customer/Customer";
import { useAppUser } from "../../providers/AppUserProvider";
import { QualpayBankAccountInput } from "./QualpayBankAccountInput";
import { DispatchProBankAccountInput } from "./DispatchProBankAccountInput";

interface BankAccountInputProps {
	customer: Customer;
	onClose: () => void;
	onCreate: (bankAccount: BankAccountPaymentMethod) => void;
}

export function BankAccountInput(props: BankAccountInputProps) {
	const { customer } = props;
	const user = useAppUser();
	if (user.paymentProcessor === "qualpay") {
		return <QualpayBankAccountInput customer={customer} onClose={props.onClose} onCreate={props.onCreate} />;
	}
	if (user.paymentProcessor === "dispatchpro") {
		return <DispatchProBankAccountInput customer={customer} onClose={props.onClose} onCreate={props.onCreate} />;
	}
	return (
		<>
			<Typography>Bank transfers have not been enabled on this account</Typography>
			<Button variant="outlined" color="secondary" onClick={props.onClose}>
				Cancel
			</Button>
		</>
	);
}
