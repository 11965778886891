import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { NumberTextField } from "../../../components/NumberTextField";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PhoneTextField } from "../../../components/PhoneTextField";
import { TimezoneSelect } from "../../../components/TimezoneSelect";
import { SurchargeSettings, Tenant } from "../../../entities/auth/Tenant";
import { useAlert } from "../../../hooks/useAlert";
import { useTenant } from "../../../providers/TenantProvider";
import { AccountService } from "../../../services/business/AccountService";
import { BusinessService } from "../../../services/business/BusinessService";
import { FieldValidationError, validationError } from "../../../services/server/ServerValidationError";
import { PercentTextField } from "../../../components/PercentTextField";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { Autocomplete } from "@material-ui/lab";
import { ButtonLink } from "../../../components/ButtonLink";
import { AddressLookupField } from "../../../components/address/AddressLookupField";

export function CompanyProfilePage() {
	const alert = useAlert();
	const tenantContext = useTenant();

	const [disabled, setDisabled] = useState(false);
	const [targetTankFillPercentage, setTargetTankFillPercentage] = useState<number | null>(null);
	const [tenant, setTenant] = useState<Tenant>();

	useEffect(() => {
		async function load() {
			const result = await AccountService.getUserTenant();
			if (result.success) {
				setTenant(result.data);
				setTargetTankFillPercentage(result.data.targetTankFillPercentage * 100);
			} else {
				alert.serverError(result);
			}
		}
		load();
	}, [alert]);

	const onSavePreferences = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDisabled(true);
		const result = await BusinessService.updatePreferences({
			targetTankFillPercentage: targetTankFillPercentage ? targetTankFillPercentage : undefined,
		});
		setDisabled(false);
		if (result.success) {
			tenantContext.update(result.data);
			alert.success("Preferences updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	if (!tenant) return <OverlayLoadingScreen />;

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography variant="h5">Company Profile</Typography>
			</Grid>
			<Grid item xs={12}>
				<BusinessInfoCard tenant={tenant} />
			</Grid>
			<Grid item lg={6} md={6} xs={12}>
				<SurchargeConfigurationCard surchargeSettings={tenant.surchargeSettings} />
			</Grid>
			<Grid item lg={4} md={6} xs={12}>
				<AutoApplyUnallocatedPaymentsCard tenant={tenant} />
			</Grid>
			<Grid item lg={4} md={6} xs={12}>
				<Card>
					<CardHeader title="Target Tank Fill" />
					<form onSubmit={onSavePreferences}>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item sm={6} xs={12}>
									<NumberTextField
										fullWidth
										disabled={disabled}
										label="Target Tank Fill %"
										variant="outlined"
										value={targetTankFillPercentage}
										onNumberChange={(value) => setTargetTankFillPercentage(value)}
									/>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions>
							<Button type="submit">Save</Button>
						</CardActions>
					</form>
				</Card>
			</Grid>
		</Grid>
	);
}

function AutoApplyUnallocatedPaymentsCard(props: { tenant: Tenant }) {
	const alert = useAlert();
	const tenantContext = useTenant();
	const { tenant } = props;

	const [disabled, setDisabled] = useState(false);

	const [autoApplyUnallocatedPayments, setAutoApplyUnallocatedPayments] = useState(tenant.autoApplyUnallocatedPayments);

	const onAutoApplyUnallocatedPaymentsSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDisabled(true);
		const result = await BusinessService.updatePreferences({ autoApplyUnallocatedPayments });
		setDisabled(false);
		if (result.success) {
			tenantContext.update(result.data);
			alert.success("Auto apply unallocated payments preference updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Card>
			<CardHeader title="Auto Apply Unallocated Payments" />
			<form onSubmit={onAutoApplyUnallocatedPaymentsSubmit}>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item md={12} xs={12}>
							<TextField
								fullWidth
								disabled={disabled}
								label="Auto Apply Unallocated Payments"
								variant="outlined"
								select
								value={autoApplyUnallocatedPayments}
								helperText="When enabled, any unallocated payments will be automatically applied to new invoices."
								onChange={(e) => setAutoApplyUnallocatedPayments(e.target.value === "true")}
							>
								<MenuItem value="false">No</MenuItem>
								<MenuItem value="true">Yes</MenuItem>
							</TextField>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions>
					<Button type="submit">Save</Button>
				</CardActions>
			</form>
		</Card>
	);
}

function SurchargeConfigurationCard(props: { surchargeSettings: SurchargeSettings | null }) {
	const { surchargeSettings } = props;
	const alert = useAlert();
	const { productCatalog } = useProductCatalog();

	const [disabled, setDisabled] = useState(false);
	const max = surchargeSettings?.max ?? 3;
	const [defaultSurcharge, setDefaultSurcharge] = useState<number | null>(surchargeSettings ? surchargeSettings.default / 100 : null);
	const [onlinePercentage, setOnlinePercentage] = useState<number | null>(surchargeSettings ? surchargeSettings.onlinePercentage / 100 : null);
	const [productListingId, setProductListingId] = useState<string | null>(surchargeSettings?.productListingId ?? null);

	const productListingOptions = useMemo(() => {
		return productCatalog.productLines.flatMap((pl) =>
			pl.availableListings.map((l) => ({
				productListingId: l.id,
				name: l.name,
				productLineName: pl.name,
			}))
		);
	}, [productCatalog]);

	const selectedProductListing = useMemo(() => {
		if (!productListingId) return null;
		return productListingOptions.find((pl) => pl.productListingId === productListingId);
	}, [productListingId, productListingOptions]);

	const onSavePreferences = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!productListingId) return alert.error("Fee listing is required");
		if (defaultSurcharge == null) return alert.error("Default surcharge is required");
		if (onlinePercentage == null) return alert.error("Online surcharge is required");
		if (defaultSurcharge > max / 100) return alert.error("Default surcharge cannot be greater than the max surcharge");
		if (onlinePercentage > max / 100) return alert.error("Online surcharge cannot be greater than the max surcharge");

		setDisabled(true);
		const result = await BusinessService.updateSurchargeConfiguration({
			default: defaultSurcharge * 100,
			onlinePercentage: onlinePercentage * 100,
			productListingId: productListingId,
		});
		setDisabled(false);
		if (result.success) {
			alert.success("Preferences updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Card>
			<CardHeader title="Credit Card Surcharge Settings" />
			<form onSubmit={onSavePreferences}>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item md={3} xs={4}>
							<PercentTextField
								fullWidth
								disabled={disabled}
								label="Default Surcharge %"
								variant="outlined"
								value={defaultSurcharge}
								onPercentChange={(value) => setDefaultSurcharge(value)}
								helperText={
									<>
										Default surcharge for credit card payments. <br /> Max: {max}%
									</>
								}
							/>
						</Grid>
						<Grid item md={3} xs={4}>
							<PercentTextField
								fullWidth
								disabled={disabled}
								label="Online Surcharge %"
								variant="outlined"
								value={onlinePercentage}
								onPercentChange={(value) => setOnlinePercentage(value)}
								helperText="Surcharge for online payments"
							/>
						</Grid>
						<Grid item md={6} xs={4}>
							{selectedProductListing == null && (
								<Autocomplete
									options={productListingOptions}
									getOptionSelected={(option, value) => option.productListingId === value.productListingId}
									getOptionLabel={(option) => option.name}
									renderOption={(option) => (
										<Box display="block">
											<Typography variant="body1">{option.name}</Typography>
											<Typography variant="body2" color="textSecondary">
												{option.productLineName}
											</Typography>
										</Box>
									)}
									fullWidth
									renderInput={(params) => <TextField {...params} label="Fee Product Listing" variant="outlined" />}
									onChange={(_event, newValue) => {
										setProductListingId(newValue ? newValue.productListingId : null);
									}}
								/>
							)}
							{selectedProductListing != null && (
								<>
									<TextField
										fullWidth
										disabled={true}
										label="Fee Product Listing"
										variant="outlined"
										value={selectedProductListing.name}
										InputProps={{
											readOnly: true,
										}}
									/>
									<ButtonLink onClick={() => setProductListingId(null)}>Change</ButtonLink>
								</>
							)}
						</Grid>
					</Grid>
				</CardContent>
				<CardActions>
					<Button type="submit">{surchargeSettings ? "Update" : "Add Surcharge"}</Button>
				</CardActions>
			</form>
		</Card>
	);
}

function BusinessInfoCard(props: { tenant: Tenant }) {
	const alert = useAlert();
	const tenantContext = useTenant();
	const { tenant } = props;

	const [disabled, setDisabled] = useState(false);
	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

	const [name, setName] = useState(tenant.name);
	const [timezone, setTimezone] = useState(tenant.timezone);
	const [officeAddress, setOfficeAddress] = useState(tenant.officeAddress);
	const [mailingAddress, setMailingAddress] = useState(tenant.mailingAddress);
	const [phoneNumber, setPhoneNumber] = useState(tenant.phoneNumber);

	const onBusinessInfoSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDisabled(true);
		setValidationErrors([]);
		const result = await BusinessService.updateInfo({
			name,
			timezone,
			officeAddress: officeAddress ? officeAddress : undefined,
			mailingAddress: mailingAddress ? mailingAddress : undefined,
			phoneNumber: phoneNumber ? phoneNumber.number : undefined,
		});
		setDisabled(false);
		if (result.success) {
			tenantContext.update(result.data);
			alert.success("Business info updated");
		} else if (result.validation) {
			setValidationErrors(result.errors);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Card>
			<CardHeader title="Business Info" />
			<form onSubmit={onBusinessInfoSubmit}>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item md={4} xs={12}>
							<TextField
								required
								fullWidth
								disabled={disabled}
								label="Name"
								variant="outlined"
								value={name}
								onChange={(e) => setName(e.target.value)}
								error={validationError.isFieldInError(validationErrors, "Name")}
								helperText={validationError.getFieldError(validationErrors, "Name")}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<PhoneTextField
								fullWidth
								disabled={disabled}
								value={phoneNumber}
								onChangePhoneNumber={(p) => setPhoneNumber(p)}
								label="Phone Number"
								variant="outlined"
								allowExtensions={false}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<AddressLookupField
								label="Mailing Address"
								onSelect={setMailingAddress}
								fullWidth
								disabled={disabled}
								value={mailingAddress}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<AddressLookupField
								label="Office Address"
								onSelect={setOfficeAddress}
								fullWidth
								disabled={disabled}
								value={officeAddress}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<TimezoneSelect
								required
								variant="outlined"
								fullWidth
								disabled={disabled}
								value={timezone}
								onChange={setTimezone}
								error={validationError.isFieldInError(validationErrors, "Timezone")}
								helperText={validationError.getFieldError(validationErrors, "Timezone")}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions>
					<Button type="submit">Save</Button>
				</CardActions>
			</form>
		</Card>
	);
}
