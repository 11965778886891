import React, {  } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useAppUser } from "../../../providers/AppUserProvider";
import { CreditCardInput } from "../../../components/payment/CreditCardInput";
import { CreditCardPaymentMethod, PaymentMethod } from "../../../entities/billing/PaymentMethod";
import { Customer } from "../../../entities/customer/Customer";
import { BankAccountInput } from "../../../components/payment/BankAccountInput";

interface AddPaymentMethodDialogProps {
	open: boolean;
	customer: Customer;
	type: "card" | "bank";
	onClose: () => void;
	onCreate: (paymentMethod: PaymentMethod) => void;
}

export function AddPaymentMethodDialog(props: AddPaymentMethodDialogProps) {
	const { customer, type } = props;
	const user = useAppUser();

	const canAddPaymentMethods = user.paymentProcessor !== "none";

	const onClose = () => {
		props.onClose();
	};

	const onCreate = (paymentMethod: PaymentMethod) => {
		props.onCreate(paymentMethod);
	};

	return (
		<Dialog open={props.open} onClose={onClose}>
			{!canAddPaymentMethods && <NoProcessorConfigured onClose={onClose} />}
			{canAddPaymentMethods && type === "card" && <CreditCard customer={customer} onClose={onClose} onCreate={onCreate} />}
			{canAddPaymentMethods && type === "bank" && <BankAccount customer={customer} onClose={onClose} onCreate={onCreate} />}
		</Dialog>
	);
}

interface CreditCardProps {
	customer: Customer;
	onClose: () => void;
	onCreate: (creditCard: CreditCardPaymentMethod) => void;
}

function CreditCard(props: CreditCardProps) {
	const { customer } = props;
	return (
		<>
			<DialogTitle style={{ paddingBottom: 0 }}>Add Credit Card</DialogTitle>
			<DialogContent>
				<CreditCardInput customer={customer} onClose={props.onClose} onCreate={props.onCreate} />
			</DialogContent>
		</>
	);
}

interface BankAccountProps {
	customer: Customer;
	onClose: () => void;
	onCreate: (bankAccount: PaymentMethod) => void;
}

function BankAccount(props: BankAccountProps) {
	const { customer } = props;
	return (
		<>
			<DialogTitle style={{ paddingBottom: 0 }}>Add Bank Account</DialogTitle>
			<DialogContent>
				<BankAccountInput customer={customer} onClose={props.onClose} onCreate={props.onCreate} />
			</DialogContent>
		</>
	);
}


function NoProcessorConfigured(props: { onClose: () => void }) {
	return (
		<>
			<DialogTitle>Payment Processor Not Configured</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">
					Your organization has not configured a payment processor. Please contact DispatchPro support if you are interested in taking credit cards / bank transfers.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={props.onClose}>
					Close
				</Button>
			</DialogActions>
		</>
	);
}
