import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from "@material-ui/core";
import React from "react";
import { runningOnProd } from "../../AppEnv";
import { BankAccountPaymentMethod } from "../../entities/billing/PaymentMethod";
import { Customer } from "../../entities/customer/Customer";
import { useAlert } from "../../hooks/useAlert";
import { PaymentMethodService } from "../../services/billing/PaymentMethodService";
import { ButtonLink } from "../ButtonLink";

interface QualpayBankAccountInputProps {
	customer: Customer;
	onClose: () => void;
	onCreate: (bankAccount: BankAccountPaymentMethod) => void;
}

export function QualpayBankAccountInput(props: QualpayBankAccountInputProps) {
	const { customer } = props;
	const alert = useAlert();

	const [disabled, setDisabled] = React.useState(false);
	const [firstName, setFirstName] = React.useState(customer.firstName);
	const [lastName, setLastName] = React.useState(customer.lastName);
	const [routingNumber, setRoutingNumber] = React.useState("");
	const [accountNumber, setAccountNumber] = React.useState("");
	const [accountType, setAccountType] = React.useState<"checking" | "savings">("checking");
	const [accountHolderType, setAccountHolderType] = React.useState<"personal" | "business">("personal");

	const onSubmit = async (e: React.FormEvent<unknown>) => {
		e.preventDefault();
		setDisabled(true);
		const result = await PaymentMethodService.addQualpayBankAccount({
			customerId: customer.id,
			firstName,
			lastName,
			routingNumber,
			accountNumber,
			accountType: `${accountHolderType} ${accountType}`,
		});
		setDisabled(false);
		if (result.success) {
			props.onCreate(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<form style={{ width: 450 }} onSubmit={onSubmit}>
			{!runningOnProd() && (
				<ButtonLink
					style={{ marginBottom: 15 }}
					onClick={() => {
						setRoutingNumber("122105278");
						setAccountNumber("0000000008");
					}}
				>
					Use Test Account
				</ButtonLink>
			)}
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<FormHelperText style={{ marginBottom: 5 }}>Name on Account</FormHelperText>
					<Grid container spacing={2}>
						<Grid item sm={6} xs={12}>
							<TextField fullWidth label="First Name" variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)} disabled={disabled} />
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField fullWidth label="Last Name" variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)} disabled={disabled} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<FormHelperText style={{ marginBottom: 5 }}>Bank Account Info</FormHelperText>
					<Grid container spacing={2}>
						<Grid item sm={6} xs={12}>
							<TextField label="Routing Number" variant="outlined" required fullWidth value={routingNumber} onChange={(e) => setRoutingNumber(e.target.value)} />
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField label="Account Number" variant="outlined" required fullWidth value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={6} xs={12}>
					<FormControl component="fieldset" disabled={disabled}>
						<FormLabel component="legend">Type</FormLabel>
						<RadioGroup aria-label="Account Type" value={accountType} onChange={(e) => setAccountType(e.target.value as "checking")}>
							<FormControlLabel value="checking" control={<Radio />} label="Checking" />
							<FormControlLabel value="savings" control={<Radio />} label="Savings" />
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item sm={6} xs={12}>
					<FormControl component="fieldset" disabled={disabled}>
						<FormLabel component="legend">Account Holder</FormLabel>
						<RadioGroup aria-label="Account Holder" value={accountHolderType} onChange={(e) => setAccountHolderType(e.target.value as "personal")}>
							<FormControlLabel value="personal" control={<Radio />} label="Personal" />
							<FormControlLabel value="business" control={<Radio />} label="Business" />
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item>
					<Button variant="outlined" color="secondary" onClick={props.onClose} disabled={disabled}>
						Cancel
					</Button>
				</Grid>
				<Grid item>
					<Button variant="contained" color="primary" type="submit" disabled={disabled}>
						Submit
					</Button>
				</Grid>
			</Grid>
		</form>
	);
}
