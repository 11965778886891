import React from "react";
import { DispatchProPlan } from "../../../entities/admin/DispatchProPlan";
import { FetchOptionalResult, FetchResult } from "../../../services/server/WebClient";
import { BusinessService } from "../../../services/business/BusinessService";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../../components/ServerErrorView";
import {
	Card,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	List,
	ListItem,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import { useAlert } from "../../../hooks/useAlert";
import { DispatchProInvoice } from "../../../entities/admin/DispatchProInvoice";
import { Price } from "../../../components/Price";
import { LinkTableRow } from "../../../components/LinkTableRow";
import { routes } from "../../../routes";
import { DispatchProBankAccountForm } from "../../../components/payment/DispatchProBankAccountForm";

export function PlanPage() {
	const [planResult, setPlanResult] = React.useState<FetchOptionalResult<DispatchProPlan>>();
	const [invoicesResult, setInvoicesResult] = React.useState<FetchResult<DispatchProInvoice[]>>();

	React.useEffect(() => {
		async function fetchPlan() {
			const result = await BusinessService.getPlan();
			setPlanResult(result);
		}
		fetchPlan();
	}, []);

	React.useEffect(() => {
		async function fetchInvoices() {
			const result = await BusinessService.getPlanInvoices();
			setInvoicesResult(result);
		}
		fetchInvoices();
	}, []);

	if (!planResult || !invoicesResult) {
		return <OverlayLoadingScreen />;
	}

	if (planResult.type === "error") {
		return <ServerErrorView serverError={planResult} />;
	}

	if (invoicesResult.type === "error") {
		return <ServerErrorView serverError={invoicesResult} />;
	}

	return (
		<>
			{planResult.type === "missing" && (
				<>
					<Typography variant="h1">Your plan has not been configured yet.</Typography>
					<Typography variant="h1">Please contact us for automated billing.</Typography>
				</>
			)}
			{planResult.type === "success" && <PlanView plan={planResult.data} />}
			<InvoiceTable invoices={invoicesResult.data} />
		</>
	);
}

function InvoiceTable(props: { invoices: DispatchProInvoice[] }) {
	const { invoices } = props;
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h4">Invoices</Typography>
			</Grid>

			<Grid item md={8} xs={12}>
				{invoices.length === 0 && <Typography variant="h6">No invoices yet.</Typography>}
				{invoices.length > 0 && (
					<TableContainer component={Card}>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Invoice #</TableCell>
									<TableCell>Invoice Date</TableCell>
									<TableCell>Due Date</TableCell>
									<TableCell>Amount</TableCell>
									<TableCell>Due</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.invoices.map((invoice, index) => (
									<LinkTableRow to={routes.business.resolve.planInvoicePage(invoice.id)} key={index}>
										<TableCell>{invoice.id}</TableCell>
										<TableCell>{invoice.invoiceDate.toLocaleDateString()}</TableCell>
										<TableCell>{invoice.dueDate.toLocaleDateString()}</TableCell>
										<TableCell>
											<Price value={invoice.total} />
										</TableCell>
										<TableCell>
											<Price value={invoice.balance} />
										</TableCell>
									</LinkTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Grid>
		</Grid>
	);
}

function PlanView(props: { plan: DispatchProPlan }) {
	const [plan, setPlan] = React.useState(props.plan);

	const [showPaymentMethodDialog, setShowPaymentMethodDialog] = React.useState(false);

	const onPaymentMethodUpdate = (plan: DispatchProPlan) => {
		setPlan(plan);
		setShowPaymentMethodDialog(false);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h1">{plan.name}</Typography>
				<Typography variant="h5">{plan.description}</Typography>
			</Grid>
			<Grid item md={4} xs={12}>
				<List>
					<ListItem>
						<ListItemText primary="Start date" secondary={plan.startDate.toLocaleDateString()} />
						<ListItemText primary="Next bill date" secondary={plan.nextChargeDate.toLocaleDateString()} />
					</ListItem>
					<ListItem button onClick={() => setShowPaymentMethodDialog(true)}>
						<ListItemText primary="Payment Method" secondary={plan.paymentMethodDescription ?? "Click here to setup"} />
					</ListItem>
				</List>
			</Grid>

			<Grid item md={4} xs={12}>
				<Typography variant="h6">Included Items</Typography>
				<TableContainer component={Card}>
					<Table size="small">
						<TableBody>
							{plan.lineItems.map((item, index) => (
								<TableRow key={index}>
									<TableCell>{item.description}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<AddPaymentMethodDialog open={showPaymentMethodDialog} onClose={() => setShowPaymentMethodDialog(false)} onUpdate={onPaymentMethodUpdate} />
		</Grid>
	);
}

function AddPaymentMethodDialog(props: { open: boolean; onClose: () => void; onUpdate: (plan: DispatchProPlan) => void }) {
	const alert = useAlert();
	const [loading, setLoading] = React.useState(false);

	const setPlanBankAccount = async (token: string) => {
		setLoading(true);
		const result = await BusinessService.setPlanBankAccount(token);
		setLoading(false);
		if (result.success) {
			props.onUpdate(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onClose = () => {
		if (loading) return;
		props.onClose();
	};

	return (
		<Dialog open={props.open} onClose={onClose}>
			<DialogTitle>Link Bank Account</DialogTitle>
			<DialogContent>
				<DialogContentText>By linking your bank account, you agree that we may charge your account for any future balances due.</DialogContentText>
				<DispatchProBankAccountForm disabled={loading} onCancel={onClose} onSubmit={setPlanBankAccount} />
			</DialogContent>
		</Dialog>
	);
}
