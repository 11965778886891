import {
	Box,
	Button,
	ButtonBase,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	makeStyles,
	Switch,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GridGrow } from "../../../components/GridGrow";
import { Customer } from "../../../entities/customer/Customer";
import { routes } from "../../../routes";
import { CustomerTank, PropaneTankService } from "../../../services/customer/PropaneTankService";
import { useCustomerDetailPage } from "../CustomerDetailPage";
import { printDate } from "../../../utility/PrintDate";
import { GeocodedAddress } from "../../../entities/customer/GeocodedAddress";
import { NumberTextField } from "../../../components/NumberTextField";
import { useAlert } from "../../../hooks/useAlert";
import { BackButton } from "../../../components/BackButton";
import { AddressLookupField } from "../../../components/address/AddressLookupField";

export function CustomerTankDetailCard() {
	const history = useHistory();
	const context = useCustomerDetailPage();
	const { profile } = context;

	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const openAddTankDialog = query.get("dialog") === "addTank";

	const tanks = profile.tanks;

	const openAddTank = () => {
		history.push(routes.app.resolve.customerDetailPage(profile.customer.id) + "?dialog=addTank");
	};

	const closeAddTank = () => {
		history.replace(routes.app.resolve.customerDetailPage(profile.customer.id));
	};


	return (
		<Card style={{ paddingBottom: 5 }}>
			<AddTankDialog open={openAddTankDialog} onClose={closeAddTank} />
			<Box paddingLeft={2} paddingTop={1} paddingRight={2}>
				<Grid container>
					<Grid item>
						<Typography variant="h6">Tanks</Typography>
					</Grid>
					<GridGrow />
					<Grid item>
						<Button size="small" variant="outlined" onClick={openAddTank}>
							Add Tank
						</Button>
					</Grid>
				</Grid>
			</Box>

			<Box pt={1}>
				{tanks.map((tank, i) => (
					<React.Fragment key={tank.id}>
						{i > 0 && <Divider />}
						<TankListItem tank={tank} customer={profile.customer} />
					</React.Fragment>
				))}
			</Box>
		</Card>
	);
}

function TankListItem(props: { tank: CustomerTank; customer: Customer }) {
	const { tank } = props;
	const history = useHistory();
	const classes = useStyles();

	return (
		<ButtonBase className={classes.tankRowButton} onClick={() => history.push(routes.app.resolve.propaneTankDetailPage(tank.id))} style={{ width: "100%", padding: 10 }}>
			<Grid container spacing={1} alignItems="center">
				<Grid item>
					<Typography variant="body1">{tank.gallons}</Typography>
				</Grid>
				<Grid item>
					{tank.description != null && <Typography variant="body1">{tank.description}</Typography>}
					{tank.description == null && (
						<Typography color="textSecondary" variant="body1">
							Not Set
						</Typography>
					)}
				</Grid>
				<GridGrow />
				{tank.lastThreeFills.length > 0 && (
					<Grid item>
						{tank.lastReading && (<Typography style={{ textTransform: "none" }} align="left">
							Level {tank.lastReading.percentRemaining}% | {printDate.shortDate(tank.lastReading.timeOfReading)}
						</Typography>)}
						<Typography style={{ textTransform: "none" }} align="left">
							Last {tank.lastThreeFills[0].amountFilled}g{" | "}
							{printDate.shortDate(tank.lastThreeFills[0].timeOfFill)}
							{tank.nextExpectedFill && (
								<>
									<br />
									Next {printDate.shortDate(tank.nextExpectedFill)}
									{tank.isInAlert && " [ACTIVE ALERT]"}
								</>
							)}
						</Typography>
						
					</Grid>
				)}
			</Grid>
		</ButtonBase>
	);
}

function AddTankDialog(props: { open: boolean; onClose: () => void }) {
	const alert = useAlert();
	const context = useCustomerDetailPage();
	const { profile, updateProfile } = context;
	const customerId = profile.customer.id;

	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down("xs"));

	const [gallons, setGallons] = useState<number | null>(null);
	const [description, setDescription] = useState<string | null>(null);
	const [serialNumber, setSerialNumber] = useState<string | null>(null);
	const [address, setAddress] = useState<GeocodedAddress | null>(null);
	const [isRental, setIsRental] = useState<boolean>(false);
	const [disabled, setDisabled] = useState<boolean>(false);

	const submit = async () => {
		if (gallons == null) return;
		setDisabled(true);
		const result = await PropaneTankService.create({
			customerId,
			gallons,
			locationDescription: description,
			serialNumber,
			address: address ?? profile.customer.address,
			isRental,
		});
		setDisabled(false);
		if (result.success) {
			updateProfile((p) => ({ ...p, tanks: [...p.tanks, result.data] }));
			alert.success("Tank Added");
			props.onClose();
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const disableAdd = gallons == null || disabled;

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm" fullScreen={isXs}>
			<DialogTitle>{isXs && <BackButton onClick={props.onClose} />}Add Tank</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={7}>
						<NumberTextField
							label="Capacity"
							variant="outlined"
							value={gallons}
							onNumberChange={(n) => setGallons(n)}
							fullWidth
							required
							disabled={disabled}
							helperText="Total capacity of the tank (not ullage)"
							InputProps={{
								endAdornment: "gal",
							}}
						/>
					</Grid>
					<Grid item xs={5}>
						<FormControlLabel control={<Switch checked={isRental} onChange={(e) => setIsRental(e.target.checked)} color="primary" />} label="Is Rental" />
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Location Description"
							variant="outlined"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							fullWidth
							disabled={disabled}
							helperText="Example: Front House, Barn, etc."
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Serial Number"
							variant="outlined"
							value={serialNumber}
							onChange={(e) => setSerialNumber(e.target.value)}
							fullWidth
							disabled={disabled}
						/>
					</Grid>
					<Grid item xs={12}>
						<AddressLookupField
							label="Tank Address"
							fullWidth
							disabled={disabled}
							value={address}
							onSelect={setAddress}
							helperText="Defaults to customer address if not set"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="secondary" variant="outlined" disabled={disabled}>
					Cancel
				</Button>
				<Button onClick={submit} color="primary" variant="contained" disabled={disableAdd}>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const useStyles = makeStyles(() => ({
	tankRowButton: {
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.08)",
		},
	},
}));
