import { Button, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { BackButton } from "../../components/BackButton";
import { ReportService, TankAlertReport } from "../../services/report/ReportService";
import { useAlert } from "../../hooks/useAlert";
import { ClientLink } from "../../components/ClientLink";
import { routes } from "../../routes";
import { GridGrow } from "../../components/GridGrow";

export function TankAlertReportPage() {
	const alert = useAlert();

	const [disabled, setDisabled] = useState(false);
	const [report, setReport] = useState<TankAlertReport>();

	const generateReport = async () => {
		setDisabled(true);
		const result = await ReportService.tankAlertReport();
		setDisabled(false);

		if (result.success) {
			setReport(result.data);
		} else {
			alert.serverError(result);
		}
	};

	const print = () => {
		if (!report) return;
		// print report in a new window
		const printWindow = window.open("", "PRINT", "height=400,width=600");
		if (!printWindow) return;
		printWindow.document.write("<html><head><title>" + document.title + "</title>");
		// table styles
		printWindow.document.write(`
			<style>	
				@page {
					size: auto;
					margin: 5mm;
				}
				body {
					margin: 5mm;
				}
				table {
					width: 100%;
					border-collapse: collapse;
					font-size: 10px;
				}
				table, th, td {
					border: 1px solid black;
				}
				th, td {
					padding: 5px;
				}
				a {
					text-decoration: none;
					color: #000;
				}
				tr {
					page-break-inside: avoid;
				  }
			</style>
		`);
		printWindow.document.write("</head><body >");
		printWindow.document.write("<h1>" + document.title + "</h1>");
		printWindow.document.write(document.getElementById("tank-alert-report")?.innerHTML || "");
		printWindow.document.write("</body></html>");
		printWindow.document.close();
		printWindow.focus();
		printWindow.print();
		printWindow.close();
	};

	const generateCsvContent = (report: TankAlertReport) => {
		const header = `Customer,Tank,AlertType,LastFillGallons,LastFillPercentage,LastFillDate,DaysUntilNextFill\n`;
		const rows = report.items
			.map((item) => {
				const customerName = `"${item.customerName.replace(/"/g, '""')}"`;
				const tankDescription = `"${item.tankDescription.replace(/"/g, '""')}"`;
				const alertType = `"${item.alertType.replace(/"/g, '""').replace(/\n/g, " ")}"`;
				const lastFillGallons = item.lastFillGallons ? `${item.lastFillGallons} gallons` : "None";
				const lastFillPercentage = item.lastFillPercentage ? `${(item.lastFillPercentage * 100).toFixed(0)}%` : "None";
				const lastFillDate = item.lastFillDate ? `${item.lastFillDate.toLocaleDateString()} (${item.daysSinceLastFill} days ago)` : "None";
				let daysUntilNextFill;
				if (item.daysUntilNextFill == null) {
					daysUntilNextFill = "N/A";
				} else if (item.daysUntilNextFill < 0) {
					daysUntilNextFill = `${item.daysUntilNextFill * -1} days past due`;
				} else {
					daysUntilNextFill = `${item.daysUntilNextFill} days`;
				}

				return `${customerName},${tankDescription},${alertType},${lastFillGallons},${lastFillPercentage},${lastFillDate},${daysUntilNextFill}\n`;
			})
			.join("");

		return header + rows;
	};

	const downloadCsvFile = (csvContent: string) => {
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.setAttribute("href", url);
		link.setAttribute("download", "tank-alert-report.csv");
		link.style.visibility = "hidden";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const downloadCsv = () => {
		if (report) {
			const csvContent = generateCsvContent(report);
			downloadCsvFile(csvContent);
		}
	};

	return (
		<Grid container justify="center" spacing={2}>
			<PageTitle title="Tank Alert Report" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Tank Alert Report" subheader="See which customers have tanks with automatic tank alerts setup." />
						</Grid>
					</Grid>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item>
										<Button variant="contained" color="primary" disabled={disabled} onClick={generateReport}>
											Generate
										</Button>
									</Grid>
									<GridGrow />
									<Grid item>
										<Button variant="outlined" color="primary" disabled={disabled || !report} onClick={downloadCsv}>
											Download
										</Button>
									</Grid>
									<Grid item>
										<Button variant="outlined" color="primary" disabled={disabled || !report} onClick={print}>
											Print
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			{report && (
				<Grid item sm={10} xs={12}>
					<Card className="print-table" id="tank-alert-report">
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Customer</TableCell>
									<TableCell>Tank</TableCell>
									<TableCell>Alert Type</TableCell>
									<TableCell>Last Fill Gal</TableCell>
									<TableCell>Last Fill %</TableCell>
									<TableCell>Last Fill Date</TableCell>
									<TableCell>Next Fill</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>{report.totalCustomersWithAlertConfigurations} Customers</TableCell>
									<TableCell>{report.totalTanksWithAlertConfigurations} Tanks</TableCell>
									<TableCell colSpan={5} />
								</TableRow>
								{report.items.map((item, i) => (
									<TableRow key={i}>
										<TableCell>
											<ClientLink newTab to={routes.app.resolve.customerDetailPage(item.customerId)}>
												{item.customerName}
											</ClientLink>
										</TableCell>
										<TableCell>
											<ClientLink newTab to={routes.app.resolve.propaneTankDetailPage(item.tankId)}>
												{item.tankDescription}
											</ClientLink>
										</TableCell>
										<TableCell>
											{item.alertType.split("\n").map((line, index) => (
												<React.Fragment key={index}>
													{index > 0 && <br />}
													{line}
												</React.Fragment>
											))}
										</TableCell>
										<TableCell>{item.lastFillGallons ? `${item.lastFillGallons} gallons` : `None`}</TableCell>
										<TableCell>{item.lastFillPercentage ? `${(item.lastFillPercentage * 100).toFixed(0)}%` : `None`}</TableCell>
										<TableCell>{item.lastFillDate ? `${item.lastFillDate.toLocaleDateString()} (${item.daysSinceLastFill} days ago)` : "None"}</TableCell>
										<TableCell>
											{item.daysUntilNextFill == null && "N/A"}
											{item.daysUntilNextFill != null && item.daysUntilNextFill < 0 && `${item.daysUntilNextFill * -1} days past due`}
											{item.daysUntilNextFill != null && item.daysUntilNextFill >= 0 && `${item.daysUntilNextFill} days`}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Card>
				</Grid>
			)}
		</Grid>
	);
}
